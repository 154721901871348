import * as React from 'react';

function ProjectIconComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="100%"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M65.5 10H15.5C9.97715 10 5.5 14.4772 5.5 20V60C5.5 65.5228 9.97715 70 15.5 70H65.5C71.0229 70 75.5 65.5228 75.5 60V20C75.5 14.4772 71.0229 10 65.5 10Z"
        fill="white"
      />
      <path d="M13.5 18V22H21.5V18H13.5Z" fill="#252746" />
      <path d="M33.5 26V30H37.5V26H33.5Z" fill="#252746" />
      <path d="M17.5 26V30H29.5V26H17.5Z" fill="#252746" />
      <path d="M41.5 26V30H57.5V26H41.5Z" fill="#252746" />
      <path d="M17.5 34V38H33.5V34H17.5Z" fill="#252746" />
      <path d="M37.5 34V38H69.5V34H37.5Z" fill="#252746" />
      <path d="M13.5 42V46H25.5V42H13.5Z" fill="#252746" />
      <path d="M18.5 50V54H42.5V50H18.5Z" fill="#252746" />
      <path d="M46.5 50V54H50.5V50H46.5Z" fill="#252746" />
      <path d="M42.5 62V58H38.5V62H42.5Z" fill="#252746" />
      <path d="M58.5 62V58H46.5V62H58.5Z" fill="#252746" />
      <path d="M34.5 62V58H18.5V62H34.5Z" fill="#252746" />
    </svg>
  );
}

export default ProjectIconComponent;
