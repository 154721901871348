import React from 'react';
import Image from 'next/image';

import TechCrunchLogo from '/public/images/landingpage-web/TechCrunch.png';
import BostonGlobeLogo from '/public/images/landingpage-web/BostonGlobe.png';
import ForbesLogo from '/public/images/landingpage-web/Forbes.png';
import GizmodoLogo from '/public/images/landingpage-web/Gizmodo.png';
import NewYorkTimesLogo from '/public/images/landingpage-web/NewYorkTimes.png';
import LifehackerLogo from '/public/images/landingpage-web/Lifehacker.png';
import WashingtonPostLogo from '/public/images/landingpage-web/WashingtonPost.png';

export function FeaturedByCompaniesComponent() {
  return (
    <div className="mt-6 flex w-full flex-wrap items-center justify-center space-x-4 space-y-6 desktop:space-x-14">
      <Image
        alt="TechCrunch logo"
        src={TechCrunchLogo}
        className="mt-6 h-[18px] w-auto object-contain"
        fill={false}
        height={18}
        placeholder="blur"
      />
      <Image
        alt="Washington Post logo"
        src={WashingtonPostLogo}
        className="h-[23px] w-auto object-contain"
        fill={false}
        height={23}
        placeholder="blur"
      />
      <Image
        alt="New York Times logo"
        src={NewYorkTimesLogo}
        className="h-[20px] w-auto object-contain"
        fill={false}
        height={20}
        placeholder="blur"
      />
      <Image
        alt="Boston Globe logo"
        src={BostonGlobeLogo}
        className="h-[17px] w-auto object-contain"
        fill={false}
        height={17}
        placeholder="blur"
      />
      <Image
        alt="Lifehacker logo"
        src={LifehackerLogo}
        className="h-[20px] w-auto object-contain"
        fill={false}
        height={20}
        placeholder="blur"
      />
      <Image
        alt="Gizmodo logo"
        src={GizmodoLogo}
        className="h-[12px] w-auto object-contain"
        fill={false}
        height={12}
        placeholder="blur"
      />
      <Image
        alt="Forbes logo"
        src={ForbesLogo}
        className="h-[17px] w-auto object-contain"
        fill={false}
        height={17}
        placeholder="blur"
      />
    </div>
  );
}

export default FeaturedByCompaniesComponent;
