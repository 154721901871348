import PrimaryButtonLinkComponent from '@components/buttons/PrimaryButtonLink';
import NewYearSaleBannerDiscountComponent from '@components/icons/landingpage/newYearSaleBannerDiscount';
import {
  NewYearSaleBannerTransitionComponentLG,
  NewYearSaleBannerTransitionComponentMD,
  NewYearSaleBannerTransitionComponentSM,
} from '@components/icons/landingpage/newYearSaleBannerTransition';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState, useEffect } from 'react';

function NewYearSaleBanner() {
  const currentUrl = useRouter().asPath;

  const startDate = new Date('2024-12-26T00:00:00');
  const endDate = new Date('2025-01-03T00:00:00');

  const timeLeft = useCountdown(startDate, endDate);
  const hasStarted = new Date() >= startDate;
  const isOver = new Date() >= endDate;

  return (
    hasStarted &&
    !isOver && (
      <Link
        href={{
          pathname: '/go-to-checkout',
          query: {
            interval: 'annually',
            couponId: process.env.NEXT_PUBLIC_NEW_YEAR_SALE_50_COUPON_CODE,
            cancelUrl: currentUrl,
            upgradeType: 'max',
          },
        }}
        className="z-0 flex h-auto w-full justify-between bg-marketing-accent-purple pr-4 text-product2-content-primary"
      >
        <div className="flex flex-col">
          <div className="flex flex-grow flex-row ">
            <div className="flex items-center bg-marketing-background-primary px-6 tablet:px-10">
              <div className="flex flex-col">
                <NewYearSaleBannerDiscountComponent className="h-[40px]" />
              </div>
            </div>
            <div className="h-full">
              <NewYearSaleBannerTransitionComponentSM className="hidden h-full min-h-[64px] md:block" />
              <NewYearSaleBannerTransitionComponentMD className="block h-full min-h-[64px] md:hidden" />
              <NewYearSaleBannerTransitionComponentLG className="block h-full min-h-[64px] sm:hidden" />
            </div>
          </div>
        </div>
        <div className="ml-8 flex w-full flex-col items-start   justify-between md:items-center tablet:flex-row">
          <h3 className="pt-4 font-aeonikFono text-lg sm:text-xl md:py-0 md:text-2xl">
            Gift yourself a more fulfilling career
          </h3>
          <div className="flex items-center">
            <div className="mr-8 flex items-center space-x-1 py-4 text-center md:p-0">
              <CounterItem timeLeft={timeLeft?.days} />
              <CounterItem timeLeft={timeLeft?.hours} />
              <CounterItem timeLeft={timeLeft?.minutes} />
              <CounterItem timeLeft={timeLeft?.seconds} isLast />
            </div>
          </div>
        </div>
        <div className="mr-4 flex items-center">
          <PrimaryButtonLinkComponent
            className="hidden text-nowrap bg-product2-accent-success px-6 py-3 text-product2-content-inverse hover:bg-product2-green-400 sm:block "
            href={{
              pathname: '/go-to-checkout',
              query: {
                interval: 'annually',
                couponId: process.env.NEXT_PUBLIC_NEW_YEAR_SALE_50_COUPON_CODE,
                cancelUrl: currentUrl,
                upgradeType: 'max',
              },
            }}
          >
            Claim offer
          </PrimaryButtonLinkComponent>
        </div>
      </Link>
    )
  );
}

function CounterItem({
  timeLeft,
  isLast,
}: {
  timeLeft?: string;
  isLast?: boolean;
}) {
  return (
    <>
      <div className="bg-product2-background-dark p-1 font-aeonikMono text-lg sm:p-2">
        {timeLeft ?? '00'}
      </div>
      {!isLast && <span> : </span>}
    </>
  );
}

export default NewYearSaleBanner;

function useCountdown(startDate: Date, endDate: Date) {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  function calculateTimeLeft() {
    const now = new Date();
    let difference = endDate.getTime() - now.getTime();

    if (now < startDate) {
      difference = endDate.getTime() - startDate.getTime();
    }

    let timeLeft = {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
    };

    if (difference > 0) {
      timeLeft = {
        days: String(Math.floor(difference / (1000 * 60 * 60 * 24))).padStart(
          2,
          '0',
        ),
        hours: String(
          Math.floor((difference / (1000 * 60 * 60)) % 24),
        ).padStart(2, '0'),
        minutes: String(Math.floor((difference / 1000 / 60) % 60)).padStart(
          2,
          '0',
        ),
        seconds: String(Math.floor((difference / 1000) % 60)).padStart(2, '0'),
      };
    }
    return timeLeft;
  }

  return timeLeft;
}
