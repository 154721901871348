import { twMerge } from 'tailwind-merge';

const Heading1Style = `font-aeonikFono text-[32px] font-bold leading-[44px]`;
export function Heading1({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <h1 className={twMerge(Heading1Style, className)}>{children}</h1>;
}
Heading1.style = Heading1Style;

const Heading2Style = `font-aeonikFono text-[24px] font-bold leading-[32px]`;
export function Heading2({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <h2 className={twMerge(Heading2Style, className)}>{children}</h2>;
}
Heading2.style = Heading2Style;

const Heading3Style = `font-aeonikFono text-[18px] font-bold leading-[28px]`;
export function Heading3({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <h3 className={twMerge(Heading3Style, className)}>{children}</h3>;
}
Heading3.style = Heading3Style;

const Heading4Style = `font-aeonikFono text-[16px] font-bold leading-[24px]`;
export function Heading4({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <h4 className={twMerge(Heading4Style, className)}>{children}</h4>;
}
Heading4.style = Heading4Style;

const Paragraph1Style = `font-mimopro text-[18px] leading-[28px]`;
export function Paragraph1({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <h4 className={twMerge(Paragraph1Style, className)}>{children}</h4>;
}
Paragraph1.style = Paragraph1Style;

const Paragraph2Style = `font-mimopro text-[16px] leading-[24px]`;
export function Paragraph2({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <p className={twMerge(Paragraph2Style, className)}>{children}</p>;
}
Paragraph2.style = Paragraph2Style;

const Paragraph3Style = `font-mimopro text-[14px] leading-[22px]`;
export function Paragraph3({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <p className={twMerge(Paragraph3Style, className)}>{children}</p>;
}
Paragraph3.style = Paragraph3Style;

const Paragraph4Style = `font-mimopro text-[12px] leading-[16px]`;
export function Paragraph4({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <p className={twMerge(Paragraph4Style, className)}>{children}</p>;
}
Paragraph4.style = Paragraph4Style;

const Label1Style = `font-mimopro text-[14px] font-medium leading-[22px]`;
export function Label1({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <h4 className={twMerge(Label1Style, className)}>{children}</h4>;
}
Label1.style = Label1Style;

const Label2Style = `font-mimopro text-[14px] font-medium leading-[22px]`;
export function Label2({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <h4 className={twMerge(Label2Style, className)}>{children}</h4>;
}
Label3.style = Label2Style;

const Label3Style = `font-mimopro text-[12px] font-medium leading-[18px]`;
export function Label3({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <h4 className={twMerge(Label3Style, className)}>{children}</h4>;
}
Label3.style = Label3Style;

const PathCardTitleStyle = `font-aeonikFono text-[20px] font-bold leading-[28px]`;
export function PathCardTitle({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <p className={twMerge(PathCardTitleStyle, className)}>{children}</p>;
}
PathCardTitle.style = PathCardTitleStyle;

const Title1Style = `font-mimopro text-[24px] font-bold leading-[32px]`;
export function Title1({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <p className={twMerge(Title1Style, className)}>{children}</p>;
}
Title1.style = Title1Style;

const Title2Style = `font-mimopro text-[18px] font-bold leading-[28px]`;
export function Title2({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <p className={twMerge(Title2Style, className)}>{children}</p>;
}
Title2.style = Title2Style;

const Title3Style = `font-mimopro text-[16px] font-bold leading-[24px]`;
export function Title3({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <p className={twMerge(Title3Style, className)}>{children}</p>;
}
Title3.style = Title3Style;

const Title4Style = `font-mimopro text-[14px] font-bold leading-[22px]`;
export function Title4({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <p className={twMerge(Title4Style, className)}>{children}</p>;
}
Title4.style = Title4Style;

const Button1TextStyle = `font-mimopro text-[16px] font-bold leading-[24px]`;
export function Button1Text({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <p className={twMerge(Button1TextStyle, className)}>{children}</p>;
}
Button1Text.style = Button1TextStyle;

const Button2TextStyle = `font-mimopro text-[14px] font-bold leading-[22px]`;
export function Button2Text({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <p className={twMerge(Button2TextStyle, className)}>{children}</p>;
}
Button2Text.style = Button2TextStyle;

const LinkSmallTextStyle = `font-mimopro text-[12px] font-semibold leading-[16px]`;
export function LinkSmallText({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <p className={twMerge(LinkSmallTextStyle, className)}>{children}</p>;
}
LinkSmallText.style = LinkSmallTextStyle;

const Hero1TextStyle = `font-aeonikFono text-[56px] font-bold leading-[64px]`;
export function Hero1Text({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <h1 className={twMerge(Hero1TextStyle, className)}>{children}</h1>;
}
Hero1Text.style = Hero1TextStyle;

const Hero2TextStyle = `font-aeonikFono text-[48px] font-bold leading-[64px]`;
export function Hero2Text({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <h1 className={twMerge(Hero2TextStyle, className)}>{children}</h1>;
}
Hero2Text.style = Hero2TextStyle;

export function BodyTextSmall({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <p
      className={`${className} text-[14px] leading-[20px] tablet:text-[16px] tablet:leading-[24px]`}
    >
      {children}
    </p>
  );
}

export function BodyText({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <p
      className={`${className} text-[18px] leading-[28px] tablet:text-[20px] tablet:leading-[32px]`}
    >
      {children}
    </p>
  );
}

export function TextLink({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <p
      className={`${className} text-[14px] leading-[20px] hover:underline tablet:text-[18px] tablet:leading-[24px]`}
    >
      {children}
    </p>
  );
}
