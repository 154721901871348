import { Heading2, Quote, Caption } from '@components/web-landingpage/Texts';
import Image, { StaticImageData } from 'next/image';
import testament1 from '/public/images/landingpage-web/testament_1.png';
import testament2 from '/public/images/landingpage-web/testament_2.png';
import testament4 from '/public/images/landingpage-web/testament_4.png';
import QuoteStartLightIconComponent from '@components/icons/quoteStartLight';
import QuoteEndDarkIconComponent from '@components/icons/quoteEndDark';
import { SuccessStoriesConfetti } from './ConfettiBackground';

export default function SuccessStoriesComponent() {
  return (
    <div className="relative z-10 space-y-6">
      <SuccessStoriesConfetti />
      <div className="hidden w-full tablet:grid tablet:grid-cols-1 tablet:gap-6 desktop:grid-cols-2">
        <div>
          <Heading2 className="mb-16 pl-6 text-marketing-text-primary tablet:pl-0">
            Mimo learners have inspiring stories to tell
          </Heading2>
        </div>
        <QuoteCard
          src={testament1}
          author="Clément Durandeau"
          location="🇫🇷 France"
        >
          “The coding skills acquired through Mimo&apos;s courses made me feel
          more confident about my ability to code and create software.”
        </QuoteCard>
        <QuoteCard src={testament2} author="Stephanie Tyers" location="🇺🇸 USA">
          “My goal is to learn how to code so that when I re-enter the
          workforce, I&apos;ll be a more competitive job candidate.”
        </QuoteCard>
        <QuoteCard src={testament4} author="Sam Phelan" location="🇺🇸 USA">
          “I&apos;ve tried quite a few coding platforms but none of them matched
          the ease of learning I experienced with Mimo.”
        </QuoteCard>
      </div>
      <div className="flex w-full justify-center tablet:hidden">
        <div className="flex w-full flex-col space-y-10">
          <Heading2 className="px-6 text-center text-marketing-text-primary">
            Mimo learners have inspiring stories to tell
          </Heading2>
          <div className="min-w-screen sitems-center flex w-full max-w-full snap-x flex-col overflow-x-auto pl-6 tablet:hidden">
            <div className="min-w-screen flex w-full max-w-7xl space-x-4 ">
              <QuoteCard
                src={testament1}
                author="Clément Durandeau"
                location="🇫🇷 France"
              >
                “The coding skills acquired through Mimo&apos;s courses made me
                feel more confident about my ability to code and create
                software.”
              </QuoteCard>
              <QuoteCard
                src={testament2}
                author="Stephanie Tyers"
                location="🇺🇸 USA"
                style={{ objectPosition: '24% 65%' }}
              >
                “My goal is to learn how to code so that when I re-enter the
                workforce, I&apos;ll be a more competitive job candidate.”
              </QuoteCard>
              <QuoteCard
                src={testament4}
                author="Sam Phelan"
                location="🇺🇸 USA"
                style={{ objectPosition: '24% 26%' }}
              >
                “I&apos;ve tried quite a few coding platforms but none of them
                matched the ease of learning I experienced with Mimo.”
              </QuoteCard>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function QuoteCard({
  src,
  author,
  location,
  children,
  style,
}: {
  src: StaticImageData;
  author: string;
  location: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}) {
  return (
    <>
      <div className="relative flex max-h-[460px] min-w-[342px] snap-center flex-col rounded-3xl tablet:max-h-[300px] tablet:flex-row [&:nth-child(3)]:relative [&:nth-child(3)]:after:absolute [&:nth-child(3)]:after:-right-6 [&:nth-child(3)]:after:h-full [&:nth-child(3)]:after:w-6 [&:nth-child(3)]:after:content-[''] tablet:[&:nth-child(3)]:after:-right-10 tablet:[&:nth-child(3)]:after:hidden tablet:[&:nth-child(3)]:after:w-10">
        <div className="max-h-[200px] overflow-auto rounded-t-3xl tablet:h-full tablet:max-h-full tablet:w-[180px] tablet:rounded-t-none">
          <QuoteStartLightIconComponent className="absolute left-5 top-5" />
          <Image
            src={src}
            style={style}
            alt="Portrait photo of the auther of the quote"
            className="h-full w-full self-center object-cover"
          />
        </div>
        <div className="relative z-20 flex flex-1 flex-shrink flex-col justify-between space-y-10 rounded-b-3xl bg-marketing-surface-darkBlue p-6 text-marketing-text-primary tablet:space-y-6 tablet:rounded-r-3xl tablet:rounded-bl-none tablet:p-10">
          <Quote>{children}</Quote>
          <div className="flex justify-between">
            <Caption>{author}</Caption>
            <Caption>{location}</Caption>
          </div>
        </div>
        <QuoteEndDarkIconComponent className="absolute bottom-5 right-5 z-10" />
      </div>
    </>
  );
}
