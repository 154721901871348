import { assign, createMachine, fromPromise } from 'xstate';
import {
  createUserWithEmailAndPassword,
  signInWithGoogle,
  didLoginOrRegisterWeb,
} from '@utils/auth';
import { isEmail } from '@utils/typeGuards';
import { UserCredential } from 'firebase/auth';
import { mapFirebaseServiceError } from './loginStateMachine';

export const trackPlatformVisit = fromPromise(
  async ({ input }: { input: { userCredentials?: UserCredential } }) => {
    const user = input.userCredentials?.user;
    if (!user) {
      return;
    }
  },
);

export const didLoginOrRegister = fromPromise(
  async ({ input }: { input: { userCredentials?: UserCredential } }) => {
    if (input.userCredentials) {
      didLoginOrRegisterWeb(input.userCredentials);
    }
  },
);
export const submitRegister = fromPromise(
  async ({
    input,
  }: {
    input: { event: RegistrationStateEvent; email: string; password: string };
  }) => {
    switch (input.event.type) {
      case 'SUBMIT_FORM': {
        const { email, password } = input;
        const response = await createUserWithEmailAndPassword(email, password);
        return response;
      }
      case 'REGISTER_WITH_GOOGLE': {
        const response = signInWithGoogle();
        return response;
      }
      default:
        break;
    }
  },
);

export type RegistrationComponentState = InitialRegistrationState;
export type EmailError = '' | 'Email is missing' | 'Invalid email address';
export type PasswordError =
  | ''
  | 'Password is missing'
  | 'Password must be at least 6 characters';
export type TermsError = '' | 'Required';

interface InitialRegistrationState {
  email: string;
  password: string;
  checkedTerms: boolean;
  errors: {
    email: EmailError;
    password: PasswordError;
    terms: TermsError;
    serviceError: string;
  };
  data?: UserCredential;
}

interface RegistrationStateContext {
  state: RegistrationComponentState;
}

export type RegistrationStateEvent =
  | {
      type: 'ENTER_EMAIL';
      email?: string;
    }
  | {
      type: 'ENTER_PASSWORD';
      password?: string;
    }
  | {
      type: 'TOGGLE_TERMS';
      checkedTerms?: boolean;
    }
  | {
      type: 'SUBMIT_FORM';
      data: {
        email?: string;
        password?: string;
        checkedTerms?: boolean;
      };
    }
  | {
      type: 'REGISTER_WITH_GOOGLE';
    };

const registrationStateMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QCMBOB7A7rMqDKALgIYFgB0AxugDbVEAOOEA+lQHaw1is12OQBiACoB5AOJiAMgFFmAIQBKIgOp5pCxKHrpYASwK70bTSAAeiAMwA2AOxkALACYr9pxZsAGR54CcFgDQgAJ6IAIwAHI5kPgCs4RbWPqH2yeHhAL7pgWhYOPjEpJS8DEw8HFzMYKb0RGwQgqISMvJKquom2noGRibmCAC0oVZWZB5WoTYWoX5WFj7hNlaBIQgxHnZWMTaR9jFOk3ExmdkY2LiEJORVNXWQZZzU3Ne19RDC4lKyiipqGkggnX0hmM-z6UzIc0m4Rc008C2Gy0Qaw2W3CezmVjS0PsxxAOTO+UuZGetxY7AeT2qLwaH2aAGERAA5PAiGQdHRAnqgyy2MibIahCyOey2GJWHyI1aYhzWCY2Rw+ewecI+Ry4-F5C6FEmve4VHWCRkAQQAagBJMRGoSyABiIgUyiNCgAIuyusDeoh+hYPD5oo4PMqA3FYj4bJK-HZsQtQltA04fOrTpqClcqaS9Y9KunXgJjebLdb5Ea6QBpN2ckGgPre1x8mKquKORw+7z2SVpUIQ8YHIWOcJKjJZPHJ86p4k5u7k-WTt4KaSSERG53MM02hRGgCy0gr3SrZi9Fl2EPc9h9cSsvvCkpsSrIMS29gHaNjkzVw41Y6JBrJRgp2ZuXM6RkJ1mEXMQ8F3D1uQGKYuzGHwkhsPYbEVBtJSsBUyGbcIPCPGFhgiJNci-bVZ0zbgqFoEoaSaL5Wl+KCuWrRBnA8MhJimbwjyfUI8MlIULGwpU8McCJtliW9iIJLU00Aqc-wqKi+CYd46OYBlmVZHd-kBPdPVWMVsJ8TFnymQNhQwrCcLGVxYnCZIrGklNv3I6cs2Umi3nzC0rVte1HRdJj9z6FUojGI9EM8YYw3bYIwkiaI4gScVklSSJnNIuTqV-coPOKfhvNNXyizkEty10jl9JgxC-TWQdMJFftQgEltsLmeZ4WsSYcQ-UdCTI+Tcv-TzCoEedF2XVd1y3HStCq6CWIQPiElGWMAxSGw4SfSVkXrbYB0cGI+JSXqThIgawAEM1GTNIRgoMiwFgccUFTRRZlQcyVQnfYc2HQep4H+T9LqKajCoonhwaYB6YMGDwYlGAMPGSJJ4hE694oQFL2vcNY-FVSIjj6i7ZLBlSFLyykhthpb4ZGI6wwSPDYj2dCsZSRHbCOltL37MMtky0Gf0hn9aYPAY+LsexEKevnXAsVEMKcbCxMWcVZlCCJ33OmTxxF9zKIKmHKvdZiJbWEYUhVGWWxScZFYE6xcflPiXwVSYhdk8Wa0SE9b3PaEr2+31onlBY0je5tkkyTIgA */
  createMachine(
    {
      context: {
        state: {
          email: '',
          password: '',
          checkedTerms: false,
          errors: {
            email: '',
            password: '',
            terms: '',
          },
          data: undefined,
        },
      } as RegistrationStateContext,
      types: {} as {
        context: RegistrationStateContext;
        events: RegistrationStateEvent;
        actors:
          | {
              src: 'trackPlatformVisit';
              id: 'trackPlatformVisit';
              logic: typeof trackPlatformVisit;
            }
          | {
              src: 'didLoginOrRegister';
              id: 'didLoginOrRegister';
              logic: typeof didLoginOrRegister;
            }
          | {
              src: 'submitRegister';
              id: 'submitRegister';
              logic: typeof submitRegister;
            };
      },
      id: 'registrationState',
      initial: 'init',
      states: {
        init: {
          on: {
            ENTER_EMAIL: {
              actions: 'captureEmail',
            },
            ENTER_PASSWORD: {
              actions: 'capturePassword',
            },
            TOGGLE_TERMS: {
              actions: 'captureToggledTerms',
            },
            SUBMIT_FORM: [
              {
                guard: 'isFormInvalid',
                target: 'error',
              },
              {
                target: 'loading',
              },
            ],
            REGISTER_WITH_GOOGLE: [
              {
                target: 'socialRegistration',
              },
            ],
          },
        },
        loading: {
          invoke: {
            src: 'submitRegister',
            id: 'submitRegister',
            input: ({ context, event }) => ({
              event,
              email: context.state.email,
              password: context.state.password,
            }),
            onDone: {
              actions: 'registrationSuccess',
              target: 'success',
            },
            onError: [
              {
                actions: 'captureServiceError',
                target: 'serviceError',
              },
            ],
          },
        },
        socialRegistration: {
          entry: ['clearForm'],
          invoke: {
            src: 'submitRegister',
            id: 'submitRegister',
            input: ({ context, event }) => ({
              event,
              email: context.state.email,
              password: context.state.password,
            }),
            onDone: {
              actions: 'registrationSuccess',
              target: 'success',
            },
            onError: [
              {
                actions: 'captureServiceError',
                target: 'serviceError',
              },
            ],
          },
        },
        error: {
          entry: ['detectErrors'],
          on: {
            ENTER_EMAIL: {
              actions: 'captureEmail',
              target: 'init',
            },
            ENTER_PASSWORD: {
              actions: 'capturePassword',
              target: 'init',
            },
            TOGGLE_TERMS: {
              actions: 'captureToggledTerms',
              target: 'init',
            },
            REGISTER_WITH_GOOGLE: [
              {
                target: 'socialRegistration',
              },
            ],
            SUBMIT_FORM: [
              {
                guard: 'isFormInvalid',
                target: 'error',
              },
              {
                target: 'loading',
              },
            ],
          },
        },
        serviceError: {
          on: {
            ENTER_EMAIL: {
              actions: 'captureEmail',
              target: 'init',
            },
            ENTER_PASSWORD: {
              actions: 'capturePassword',
              target: 'init',
            },
            TOGGLE_TERMS: {
              actions: 'captureToggledTerms',
              target: 'init',
            },
            SUBMIT_FORM: [
              {
                guard: 'isFormInvalid',
                target: 'error',
              },
              {
                target: 'loading',
              },
            ],
            REGISTER_WITH_GOOGLE: {
              target: 'socialRegistration',
            },
          },
        },
        success: {
          invoke: [
            {
              src: 'didLoginOrRegister',
              id: 'didLoginOrRegister',
              input: ({ context }) => ({
                userCredentials: context.state.data,
              }),
            },
            {
              src: 'trackPlatformVisit',
              id: 'trackPlatformVisit',
              input: ({ context }) => ({
                userCredentials: context.state.data,
              }),
            },
          ],
        },
      },
    },
    {
      guards: {
        isFormInvalid: ({ context }) => {
          return (
            !isEmail(context.state.email) ||
            context.state.password.length < 6 ||
            context.state.checkedTerms === false
          );
        },
      },
      actions: {
        captureEmail: assign(({ context, event }) => {
          if (event.type === 'ENTER_EMAIL') {
            return {
              state: {
                ...context.state,
                email: event.email,
                errors: {
                  ...context.state.errors,
                  email: '',
                },
              } as InitialRegistrationState,
            };
          }
          return context;
        }),
        capturePassword: assign(({ context, event }) => {
          if (event.type === 'ENTER_PASSWORD') {
            return {
              state: {
                ...context.state,
                password: event.password,
                errors: {
                  ...context.state.errors,
                  password: '',
                },
              } as InitialRegistrationState,
            };
          }
          return context;
        }),
        captureToggledTerms: assign(({ context, event }) => {
          if (event.type === 'TOGGLE_TERMS') {
            return {
              state: {
                ...context.state,
                checkedTerms: event.checkedTerms,
                errors: {
                  ...context.state.errors,
                  terms: '',
                },
              } as InitialRegistrationState,
            };
          }
          return context;
        }),
        captureServiceError: assign(({ context, event }) => {
          const serviceError = mapFirebaseServiceError((event as any).error);

          return {
            state: {
              ...context.state,
              errors: {
                ...context.state.errors,
                serviceError,
              },
            } as InitialRegistrationState,
          };
        }),
        detectErrors: assign(({ context, event }) => {
          if (event.type === 'SUBMIT_FORM') {
            return {
              state: {
                ...context.state,
                errors: {
                  email: emailError(context.state.email),
                  password: passwordError(context.state.password),
                  terms: !context.state.checkedTerms ? 'Required' : '',
                },
              } as InitialRegistrationState,
            };
          }
          return context;
        }),
        clearForm: assign(({ context, event }) => {
          if (event.type === 'REGISTER_WITH_GOOGLE') {
            return {
              state: {
                ...context.state,
                email: '',
                password: '',
                terms: '',
              } as InitialRegistrationState,
            };
          }
          return context;
        }),
        registrationSuccess: assign(({ context, event }) => {
          return {
            state: {
              ...context.state,
              data: (event as any).output,
            } as InitialRegistrationState,
          };
        }),
      },
      actors: {
        submitRegister: submitRegister,
        didLoginOrRegister: didLoginOrRegister,
        trackPlatformVisit: trackPlatformVisit,
      },
    },
  );

function emailError(email: string): EmailError {
  if (email.length === 0) {
    return 'Email is missing';
  }
  if (!isEmail(email)) {
    return 'Invalid email address';
  }
  return '';
}

function passwordError(password: string): PasswordError {
  if (password.length === 0) {
    return 'Password is missing';
  }
  if (password.length < 6) {
    return 'Password must be at least 6 characters';
  }
  return '';
}

export default registrationStateMachine;
