import * as React from 'react';
import { SVGProps } from 'react';

const QuoteStartLightIconComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="50"
    height="40"
    viewBox="0 0 50 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="10" y="20" width="10" height="10" fill="white" />
    <rect y="10" width="10" height="10" fill="white" />
    <rect x="10" width="10" height="10" fill="white" />
    <rect y="20" width="10" height="10" fill="white" />
    <rect x="10" y="30" width="10" height="10" fill="white" />
    <rect y="30" width="10" height="10" fill="white" />
    <rect x="40" y="20" width="10" height="10" fill="white" />
    <rect x="30" y="10" width="10" height="10" fill="white" />
    <rect x="40" width="10" height="10" fill="white" />
    <rect x="30" y="20" width="10" height="10" fill="white" />
    <rect x="40" y="30" width="10" height="10" fill="white" />
    <rect x="30" y="30" width="10" height="10" fill="white" />
  </svg>
);
export default QuoteStartLightIconComponent;
