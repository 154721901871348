import * as React from 'react';

function CertificateIconComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="100%"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M73.9999 44.75L67.9499 48.92L70.3899 55.85C71.7399 59.66 68.8399 63.64 64.7999 63.54L57.4499 63.35L55.3599 70.39C54.2099 74.27 49.5299 75.79 46.3099 73.33L40.4799 68.86L34.6499 73.33C31.4399 75.79 26.7599 74.27 25.6099 70.39L23.5199 63.35L16.1699 63.54C12.1299 63.64 9.23991 59.66 10.5799 55.85L13.0199 48.92L6.96991 44.75C3.63991 42.46 3.63991 37.54 6.96991 35.25L13.0199 31.08L10.5799 24.15C9.22991 20.34 12.1299 16.36 16.1699 16.46L23.5199 16.65L25.6099 9.61C26.7599 5.73 31.4399 4.21 34.6599 6.67L40.4899 11.14L46.3199 6.67C49.5299 4.21 54.2099 5.73 55.3599 9.61L57.4499 16.65L64.7999 16.46C68.8399 16.36 71.7299 20.34 70.3899 24.15L67.9499 31.08L73.9999 35.25C77.3299 37.54 77.3299 42.46 73.9999 44.75Z"
        fill="#7E4BDE"
      />
      <path d="M50.5 45.74V49.74H54.5V45.74H50.5Z" fill="#252746" />
      <path d="M42.5 26V34H46.5V26H42.5Z" fill="#252746" />
      <path d="M34.5 46V54H38.5V46H34.5Z" fill="#252746" />
      <path d="M54.5 41.74V45.74H58.5V41.74H54.5Z" fill="#252746" />
      <path d="M58.5 37.74V41.74H62.5V37.74H58.5Z" fill="#252746" />
      <path d="M54.5 33.74V37.74H58.5V33.74H54.5Z" fill="#252746" />
      <path d="M50.5 29.74V33.74H54.5V29.74H50.5Z" fill="#252746" />
      <path d="M38.5 34V46H42.5V34H38.5Z" fill="#252746" />
      <path d="M30.5 34V30H26.5V34H30.5Z" fill="#252746" />
      <path d="M26.5 38V34H22.5V38H26.5Z" fill="#252746" />
      <path d="M22.5 42V38H18.5V42H22.5Z" fill="#252746" />
      <path d="M26.5 46V42H22.5V46H26.5Z" fill="#252746" />
      <path d="M30.5 50V46H26.5V50H30.5Z" fill="#252746" />
    </svg>
  );
}

export default CertificateIconComponent;
