import * as React from 'react';
const PythonDeveloperIconComponent = (props: any) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26 3.33334H6.00004C4.52728 3.33334 3.33337 4.52725 3.33337 6.00001V26C3.33337 27.4728 4.52728 28.6667 6.00004 28.6667H26C27.4728 28.6667 28.6667 27.4728 28.6667 26V6.00001C28.6667 4.52725 27.4728 3.33334 26 3.33334Z"
      fill="#AD88EF"
    />
    <path
      d="M25.6667 7.66669H6.33333C5.59695 7.66669 5 8.26364 5 9.00002V25.6667C5 26.4031 5.59695 27 6.33333 27H25.6667C26.403 27 27 26.4031 27 25.6667V9.00002C27 8.26364 26.403 7.66669 25.6667 7.66669Z"
      fill="#333661"
    />
    <path d="M8.99996 10.3333H7.66663V11.6667H8.99996V10.3333Z" fill="white" />
    <path d="M10.3333 11.6667H9V13H10.3333V11.6667Z" fill="white" />
    <path d="M11.6666 13H10.3333V14.3333H11.6666V13Z" fill="white" />
    <path d="M10.3333 14.3333H9V15.6667H10.3333V14.3333Z" fill="white" />
    <path d="M8.99996 15.6667H7.66663V17H8.99996V15.6667Z" fill="white" />
    <path d="M15.6667 10.3333H13V17H15.6667V10.3333Z" fill="#AD88EF" />
  </svg>
);
export default PythonDeveloperIconComponent;
