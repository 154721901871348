import * as React from 'react';

function JavascriptIconComponent({
  className,
  grayscale,
}: {
  className?: string;
  grayscale?: boolean;
}) {
  return (
    <svg
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 21H21V3H3V21ZM12.796 17.039C12.796 18.791 11.769 19.589 10.271 19.589C8.917 19.589 8.132 18.888 7.732 18.042L9.11 17.209C9.376 17.68 9.618 18.078 10.197 18.078C10.754 18.078 11.104 17.861 11.104 17.016V11.264H12.796V17.039ZM16.795 19.589C15.225 19.589 14.21 18.84 13.714 17.861L15.092 17.063C15.454 17.655 15.926 18.091 16.759 18.091C17.46 18.091 17.907 17.74 17.907 17.257C17.907 16.677 17.447 16.472 16.675 16.133L16.252 15.952C15.031 15.433 14.222 14.78 14.222 13.402C14.222 12.134 15.188 11.167 16.698 11.167C17.774 11.167 18.547 11.542 19.104 12.521L17.786 13.366C17.496 12.847 17.182 12.642 16.698 12.642C16.203 12.642 15.889 12.955 15.889 13.366C15.889 13.874 16.203 14.079 16.928 14.394L17.351 14.574C18.789 15.19 19.599 15.819 19.599 17.232C19.599 18.755 18.402 19.589 16.795 19.589Z"
        fill={grayscale ? 'white' : '#FFDA1B'}
      />
    </svg>
  );
}

export default JavascriptIconComponent;
