import * as React from 'react';
const CreatingWebPagesIconComponent = (props: any) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3333 23.3333L16 23L12.6667 23.3333L12 26.6667L16 27L20 26.6667L19.3333 23.3333Z"
      fill="#885BDE"
    />
    <path
      d="M26.6641 3.33301H5.33073C3.85797 3.33301 2.66406 4.52692 2.66406 5.99967V20.6663C2.66406 22.1391 3.85797 23.333 5.33073 23.333H26.6641C28.1368 23.333 29.3307 22.1391 29.3307 20.6663V5.99967C29.3307 4.52692 28.1368 3.33301 26.6641 3.33301Z"
      fill="#AD88EF"
    />
    <path
      d="M26.3359 5.33301H5.66927C4.93289 5.33301 4.33594 5.92996 4.33594 6.66634V20.6663C4.33594 21.4027 4.93289 21.9997 5.66927 21.9997H26.3359C27.0723 21.9997 27.6693 21.4027 27.6693 20.6663V6.66634C27.6693 5.92996 27.0723 5.33301 26.3359 5.33301Z"
      fill="#885BDE"
    />
    <path
      d="M26.3359 22C27.0723 22 27.6693 21.403 27.6693 20.6667V18H4.33594V20.6667C4.33594 21.403 4.93289 22 5.66927 22H26.3359Z"
      fill="#333661"
    />
    <path
      d="M21.3333 26.667H10.6667C10.2985 26.667 10 26.9655 10 27.3337V28.0003C10 28.3685 10.2985 28.667 10.6667 28.667H21.3333C21.7015 28.667 22 28.3685 22 28.0003V27.3337C22 26.9655 21.7015 26.667 21.3333 26.667Z"
      fill="#AD88EF"
    />
    <circle cx="21.6693" cy="11.0003" r="2.33333" fill="#F4D055" />
    <g clipPath="url(#clip0_7029_18456)">
      <path
        d="M9.07756 11.317C9.21094 11.1534 9.46093 11.1534 9.59432 11.317L15.8208 18.9561C15.9983 19.1738 15.8433 19.5 15.5624 19.5H3.10946C2.82854 19.5 2.6736 19.1738 2.85108 18.9561L9.07756 11.317Z"
        fill="#333661"
      />
      <path
        d="M16.4404 13.8833C16.5688 13.7619 16.7697 13.7619 16.8982 13.8833L22.4108 19.0914C22.63 19.2984 22.4835 19.667 22.1819 19.667H11.1566C10.8551 19.667 10.7085 19.2984 10.9277 19.0914L16.4404 13.8833Z"
        fill="#333661"
      />
    </g>
    <defs>
      <clipPath id="clip0_7029_18456">
        <rect
          width="20"
          height="12"
          fill="white"
          transform="translate(4.33594 10)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default CreatingWebPagesIconComponent;
