import * as React from 'react';

function FunIconComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="100%"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M61.5 14C55.92 14 51.11 17.27 48.87 22H32.13C29.88 17.27 25.08 14 19.5 14C11.77 14 5.5 20.27 5.5 28V58.58C5.5 62.68 8.82 66 12.92 66C15.73 66 18.3 64.41 19.55 61.9L24.5 52H56.5L61.45 61.9C62.71 64.41 65.27 66 68.08 66C72.18 66 75.5 62.68 75.5 58.58V28C75.5 20.27 69.23 14 61.5 14Z"
        fill="#FF7231"
      />
      <path d="M55.5 26V30H59.5V26H55.5Z" fill="#252746" />
      <path d="M63.5 26V30H67.5V26H63.5Z" fill="#252746" />
      <path d="M63.5 22H59.5V26H63.5V22Z" fill="#252746" />
      <path d="M63.5 30H59.5V34H63.5V30Z" fill="#252746" />
      <path d="M46.5 40H34.5V44H46.5V40Z" fill="#252746" />
      <path d="M46.5 36V40H50.5V36H46.5Z" fill="#252746" />
      <path d="M30.5 36V40H34.5V36H30.5Z" fill="#252746" />
      <path
        d="M17.5 22V26H13.5V30H17.5V34H21.5V30H25.5V26H21.5V22H17.5Z"
        fill="#252746"
      />
    </svg>
  );
}

export default FunIconComponent;
