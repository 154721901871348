import { Heading2, Subheading } from './Texts';
import Image from 'next/image';
import project_mockup from '../../public/images/landingpage-web/project_mockup.png';
import lesson_database from '../../public/images/landingpage-web/lesson_database.png';
import { DesktopVsMobileConfetti } from './ConfettiBackground';

export default function DesktopVsMobile() {
  return (
    <div className="relative z-10 flex flex-col space-y-10 text-marketing-text-primary">
      <DesktopVsMobileConfetti />
      <div className="m-auto flex flex-col items-center space-y-4 text-center tabletLg:w-[800px]">
        <Heading2>Learn to code anytime, from anywhere</Heading2>
        <Subheading>
          With Mimo, you can always choose the experience that suits you best –
          on your computer or on your phone.
        </Subheading>
      </div>
      <div className="hidden max-h-[672px] justify-center space-x-6 tabletLg:flex">
        <Image
          className="w-[1017px] min-w-[730px]"
          src={project_mockup}
          alt="project mockup"
          placeholder="blur"
        ></Image>
        <Image
          className="w-[310px] min-w-[220px]"
          src={lesson_database}
          alt="lesson example"
          placeholder="blur"
        ></Image>
      </div>
      <div className="relative flex h-[360px] min-h-[330px] w-full items-center justify-center space-x-8 overflow-hidden rounded-3xl bg-marketing-surface-darkBlue px-[20%] pt-10 tablet:h-[870px] tabletLg:hidden tabletLg:h-[840px]">
        <Image
          className="bg-marketing-surface-darkBlues absolute top-10 w-[207px] min-w-[207px] max-w-[416px] tablet:w-[80%] tablet:min-w-[579px]"
          src={lesson_database}
          alt="lesson example"
          placeholder="blur"
        ></Image>
      </div>
    </div>
  );
}
