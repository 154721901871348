import * as React from 'react';
function TypescriptIconComponent({
  grayscale,
  className,
}: {
  grayscale?: boolean;
  className?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        fill={grayscale ? 'white' : '#1E96FF'}
        fillRule="evenodd"
        d="M14 2H2v12h12zm-4.938 9.889c.337.666 1.028 1.176 2.097 1.176 1.094 0 1.908-.567 1.908-1.604 0-.962-.551-1.39-1.53-1.809l-.288-.123c-.493-.214-.707-.354-.707-.699 0-.28.214-.493.551-.493.33 0 .543.14.74.493l.897-.576c-.379-.666-.905-.92-1.637-.92-1.028 0-1.686.657-1.686 1.52 0 .938.551 1.382 1.382 1.736l.288.123c.526.23.838.37.838.765 0 .329-.304.567-.78.567-.568 0-.889-.296-1.136-.699zM5.995 8.411v4.58h1.152v-4.58h1.595V7.399H4.4v1.012z"
        clipRule="evenodd"
      />
    </svg>
  );
}
export default TypescriptIconComponent;
