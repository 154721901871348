import * as React from 'react';

function PlaygroundIconComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="100%"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.5 62V18C6.5 12.48 10.98 8 16.5 8H31.83C33.42 8 34.95 8.63 36.07 9.76L41.83 15.52C42.96 16.65 44.48 17.28 46.07 17.28H64.49C70.01 17.28 74.49 21.76 74.49 27.28V62C74.49 67.52 70.01 72 64.49 72H16.5C10.98 72 6.5 67.52 6.5 62Z"
        fill="#78D86B"
      />
      <path d="M50.5 49.74V53.74H54.5V49.74H50.5Z" fill="#252746" />
      <path d="M42.5 30V38H46.5V30H42.5Z" fill="#252746" />
      <path d="M34.5 50V58H38.5V50H34.5Z" fill="#252746" />
      <path d="M54.5 45.74V49.74H58.5V45.74H54.5Z" fill="#252746" />
      <path d="M58.5 41.74V45.74H62.5V41.74H58.5Z" fill="#252746" />
      <path d="M54.5 37.74V41.74H58.5V37.74H54.5Z" fill="#252746" />
      <path d="M50.5 33.74V37.74H54.5V33.74H50.5Z" fill="#252746" />
      <path d="M38.5 38V50H42.5V38H38.5Z" fill="#252746" />
      <path d="M30.5 38V34H26.5V38H30.5Z" fill="#252746" />
      <path d="M26.5 42V38H22.5V42H26.5Z" fill="#252746" />
      <path d="M22.5 46V42H18.5V46H22.5Z" fill="#252746" />
      <path d="M26.5 50V46H22.5V50H26.5Z" fill="#252746" />
      <path d="M30.5 54V50H26.5V54H30.5Z" fill="#252746" />
    </svg>
  );
}

export default PlaygroundIconComponent;
