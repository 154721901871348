import * as React from 'react';

function GitIconComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="100%"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.827 14.813l-11.64-11.64a1.722 1.722 0 00-2.427 0l-2.413 2.414 3.066 3.066a2.023 2.023 0 012.094.494c.573.573.733 1.4.48 2.106l2.96 2.96c.72-.24 1.546-.093 2.106.48.8.8.8 2.094 0 2.894a2.044 2.044 0 01-3.333-2.227l-2.76-2.76v7.253c.2.094.387.227.547.387.8.8.8 2.093 0 2.893-.8.8-2.094.8-2.894 0-.8-.8-.8-2.093 0-2.893.187-.187.414-.347.667-.44v-7.32a2.075 2.075 0 01-.667-.44c-.6-.6-.746-1.493-.44-2.24l-3.026-3.027-7.974 7.987a1.722 1.722 0 000 2.427l11.64 11.64c.667.666 1.76.666 2.427 0L28.827 17.24a1.722 1.722 0 000-2.427"
        fill="#FA6E5A"
      />
    </svg>
  );
}

export default GitIconComponent;
