import * as React from 'react';

function LoadingIconComponent(props: any) {
  return (
    <svg
      className="h-[36px] w-[36px] animate-spin"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        strokeLinecap="round"
        className="opacity-75"
        strokeWidth="4"
        stroke="currentColor"
        d="M 22 12 A 10 10 0 0 1 5 19"
      ></path>
    </svg>
  );
}

export default LoadingIconComponent;
