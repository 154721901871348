import * as React from 'react';
function SwiftIconComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 7.98488C21 7.79773 21 7.6276 21 7.44045C20.983 7.04915 20.966 6.64083 20.898 6.24953C20.83 5.85822 20.711 5.48393 20.5241 5.12665C20.3371 4.76938 20.1161 4.44612 19.8272 4.17391C19.5382 3.88469 19.2153 3.66351 18.8754 3.47637C18.5184 3.28922 18.1445 3.17013 17.7535 3.10208C17.3626 3.03403 16.9717 3 16.5637 3C16.3768 3 16.2068 3 16.0198 3C15.7989 3 15.5949 3 15.3739 3H10.5977H8.62606C8.4051 3 8.20113 3 7.98017 3C7.7932 3 7.62323 3 7.43626 3C7.33428 3 7.2323 3 7.14731 3.01701C6.85836 3.03403 6.55241 3.05104 6.26346 3.10208C5.9745 3.15312 5.68555 3.23819 5.4136 3.34026C5.32861 3.37429 5.22663 3.42533 5.14164 3.45936C4.86969 3.59547 4.63173 3.7656 4.39377 3.95274C4.32578 4.0208 4.24079 4.08884 4.17281 4.1569C3.88385 4.44612 3.66289 4.76938 3.47592 5.10964C3.28895 5.46692 3.16997 5.84121 3.10198 6.23251C3.034 6.62382 3.017 7.01512 3 7.42344C3 7.61059 3 7.78072 3 7.96787C3 8.18904 3 8.3932 3 8.61437V11.5577V15.3686C3 15.5898 3 15.794 3 16.0151C3 16.2023 3 16.3724 3 16.5595C3.017 16.9509 3.034 17.3592 3.10198 17.7505C3.16997 18.1418 3.28895 18.5161 3.47592 18.8733C3.66289 19.2306 3.88385 19.5539 4.17281 19.8261C4.46176 20.1153 4.7847 20.3365 5.12465 20.5236C5.48159 20.7108 5.85553 20.8299 6.24646 20.8979C6.63739 20.966 7.02833 21 7.43626 21C7.62323 21 7.7932 21 7.98017 21C8.20113 21 8.4051 21 8.62606 21H15.3739C15.5949 21 15.7989 21 16.0198 21C16.2068 21 16.3768 21 16.5637 21C16.9547 20.983 17.3626 20.966 17.7535 20.8979C18.1445 20.8299 18.5184 20.7108 18.8754 20.5236C19.2323 20.3365 19.5552 20.1153 19.8272 19.8261C20.1161 19.5369 20.3371 19.2136 20.5241 18.8733C20.711 18.5161 20.83 18.1418 20.898 17.7505C20.966 17.3592 21 16.9679 21 16.5595C21 16.3724 21 16.2023 21 16.0151C21 15.794 21 15.5898 21 15.3686V8.61437C21 8.41021 21 8.20605 21 7.98488Z"
        fill="#EE6E4F"
      />
      <path
        d="M16.9142 14.4303C16.9304 14.3634 16.9466 14.2965 16.9628 14.2296C17.676 11.3024 15.9416 7.82322 12.9914 6C14.2882 7.8065 14.8555 9.99771 14.353 11.9046C14.3044 12.0718 14.2558 12.2391 14.1909 12.4064C14.1261 12.3562 14.0451 12.306 13.9316 12.2558C13.9316 12.2558 10.9976 10.3824 7.8205 7.08724C7.73945 7.00361 9.52253 9.71335 11.5325 11.9046C10.5762 11.3526 7.93397 9.36209 6.24815 7.78977C6.45888 8.14103 6.70203 8.49229 6.96138 8.8101C8.35542 10.6501 10.1871 12.9082 12.3754 14.6478C10.8355 15.6179 8.66341 15.7016 6.50751 14.6478C5.97259 14.3969 5.47008 14.0791 5 13.7278C5.90775 15.2332 7.318 16.5379 9.03623 17.3073C11.0787 18.2106 13.1211 18.1604 14.6286 17.3241C14.6286 17.3241 14.6448 17.324 14.6448 17.3073C14.7097 17.2739 14.7745 17.2237 14.8393 17.1902C15.5688 16.8055 16.9952 16.4208 17.7733 17.9597C18.0002 18.3109 18.4055 16.3037 16.9142 14.4303Z"
        fill="white"
      />
    </svg>
  );
}
export default SwiftIconComponent;
