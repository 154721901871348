import * as React from 'react';

function NewYearSaleBannerDiscountComponent(
  props: React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      height="100%"
      viewBox="0 0 108 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M84.2061 7.93224H87.6814V4.15478H84.2061V7.93224ZM84.055 4.15478V0.377319L87.8325 0.377319V4.15478H91.6099V7.93224H87.8325V11.7097H84.055V7.93224H80.2776V4.15478H84.055ZM80.2776 26.8195V23.0421H84.055V19.2646H87.8325V15.4871H91.6099V11.7097H95.3874V7.93224L99.1648 7.93224V4.15478H102.942V0.377319L106.72 0.377319V4.15478L102.942 4.15478V7.93224H99.1648V11.7097H95.3874V15.4871H91.6099V19.2646H87.8325V23.0421H84.055V26.8195H80.2776ZM99.3159 23.0421H102.791V19.2646H99.3159V23.0421ZM99.1648 19.2646V15.4871H102.942V19.2646H106.72V23.0421H102.942V26.8195H99.1648V23.0421H95.3874V19.2646H99.1648Z"
        fill="white"
      />
      <path
        d="M107.12 31.4274H100.134V34.2216H105.723V35.6187H100.134V39.81H98.7374V30.0303H107.12V31.4274Z"
        fill="white"
      />
      <path
        d="M97.3402 31.4274H90.3547V34.2216H95.9431V35.6187H90.3547V39.81H88.9576V30.0303H97.3402V31.4274Z"
        fill="white"
      />
      <path
        d="M80.575 31.4833V38.357H86.1634V31.4833H80.575ZM86.1634 30.0303V31.4274H87.5605V38.4129H86.1634V39.81H80.575V38.4129H79.1779V31.4274H80.575V30.0303H86.1634Z"
        fill="white"
      />
      <path
        d="M44.7659 5.94286V34.0571H67.6231V5.94286L44.7659 5.94286ZM67.6231 0V5.71429H73.3374V34.2857H67.6231V40H44.7659V34.2857H39.0516V5.71429H44.7659V0L67.6231 0Z"
        fill="white"
      />
      <path
        d="M29.4516 22.8571H6.59447V17.1429H0.880188L0.880188 0L35.1659 0V5.71429L6.59447 5.71429L6.59447 17.1429H29.4516V22.8571H35.1659V34.2857H29.4516V40H6.59447V34.2857H0.880188L0.880188 28.5714H6.59447V34.2857H29.4516V22.8571Z"
        fill="white"
      />
    </svg>
  );
}

export default NewYearSaleBannerDiscountComponent;
