import * as React from 'react';

function TrophyIconComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="100%"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M74.5 13H63.5V10C63.5 7.79 61.71 6 59.5 6H21.5C19.29 6 17.5 7.79 17.5 10V13H6.5C4.84 13 3.5 14.34 3.5 16V28C3.5 36.27 10.23 43 18.5 43H19.66C22.61 48.84 28.34 53.04 35.12 53.84L34.5 59.99H28.5C26.29 59.99 24.5 61.78 24.5 63.99V69.99C24.5 72.2 26.29 73.99 28.5 73.99H52.5C54.71 73.99 56.5 72.2 56.5 69.99V63.99C56.5 61.78 54.71 59.99 52.5 59.99H46.5L45.88 53.84C52.66 53.03 58.38 48.84 61.34 43H62.5C70.77 43 77.5 36.27 77.5 28V16C77.5 14.34 76.16 13 74.5 13ZM9.5 28V19H17.5V34C17.5 35.01 17.6 35.99 17.74 36.96C13.13 36.57 9.5 32.71 9.5 28ZM71.5 28C71.5 32.71 67.87 36.57 63.26 36.96C63.4 35.99 63.5 35.01 63.5 34V19H71.5V28Z"
        fill="#F4D055"
      />
      <path
        d="M34.5 20H38.5V16H42.5V36H46.5V40H34.5V36H38.5V24H34.5V20Z"
        fill="#252746"
      />
    </svg>
  );
}

export default TrophyIconComponent;
