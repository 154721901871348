import * as React from 'react';

function AISparksIconComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="100%"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2532 2.63997C14.2532 7.9733 18.5865 12.3066 23.9199 12.3066V14.3066C18.5932 14.3066 14.2532 18.64 14.2532 23.9733H12.2532C12.2532 18.64 7.91988 14.3066 2.58655 14.3066V12.3066C7.91988 12.3066 12.2532 7.9733 12.2532 2.63997H14.2532ZM23 18.6666H25C25 21.0533 26.9466 23 29.3333 23V25C26.9466 25 25 26.9466 25 29.3333H23C23 26.9466 21.0533 25 18.6666 25V23C21.0533 23 23 21.0533 23 18.6666Z"
        fill="url(#paint0_linear_853_1258)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_853_1258"
          x1="15.9599"
          y1="2.63997"
          x2="15.9599"
          y2="29.3333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9C75E6" />
          <stop offset="1" stopColor="#89C8FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default AISparksIconComponent;
