import * as React from 'react';
const BuildingWebAppsIconComponent = (props: any) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3333 23.3333L16 23L12.6667 23.3333L12 26.6667L16 27L20 26.6667L19.3333 23.3333Z"
      fill="#885BDE"
    />
    <path
      d="M26.6641 3.33301H5.33073C3.85797 3.33301 2.66406 4.52692 2.66406 5.99967V20.6663C2.66406 22.1391 3.85797 23.333 5.33073 23.333H26.6641C28.1368 23.333 29.3307 22.1391 29.3307 20.6663V5.99967C29.3307 4.52692 28.1368 3.33301 26.6641 3.33301Z"
      fill="#AD88EF"
    />
    <path
      d="M26.3281 5H5.66146C4.92508 5 4.32812 5.59695 4.32812 6.33333V20.3333C4.32812 21.0697 4.92508 21.6667 5.66146 21.6667H26.3281C27.0645 21.6667 27.6615 21.0697 27.6615 20.3333V6.33333C27.6615 5.59695 27.0645 5 26.3281 5Z"
      fill="#B99AF4"
    />
    <path
      d="M21.3333 26.667H10.6667C10.2985 26.667 10 26.9655 10 27.3337V28.0003C10 28.3685 10.2985 28.667 10.6667 28.667H21.3333C21.7015 28.667 22 28.3685 22 28.0003V27.3337C22 26.9655 21.7015 26.667 21.3333 26.667Z"
      fill="#AD88EF"
    />
    <path
      d="M27.6615 6.33333C27.6615 5.59695 27.0645 5 26.3281 5H9.32812V21.6667H26.3281C27.0645 21.6667 27.6615 21.0697 27.6615 20.3333V6.33333Z"
      fill="#333661"
    />
    <path d="M18 7.33301H12V10.6663H18V7.33301Z" fill="#F4D055" />
    <path d="M18 12H12V15.3333H18V12Z" fill="#DD93B0" />
    <path d="M18 16.666H12V19.9993H18V16.666Z" fill="#885BDE" />
    <path
      d="M25.3359 7.33301H19.3359V10.6663H25.3359V7.33301Z"
      fill="#885BDE"
    />
    <path d="M25.3359 12H19.3359V15.3333H25.3359V12Z" fill="#F4D055" />
    <path d="M25.3359 16.666H19.3359V19.9993H25.3359V16.666Z" fill="#DD93B0" />
  </svg>
);
export default BuildingWebAppsIconComponent;
