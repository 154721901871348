import LoadingIconComponent from '@components/icons/loadingIcon';
import Link from 'next/link';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import { UrlObject } from 'url';

const PrimaryButtonLinkComponent = React.forwardRef(
  (
    {
      className,
      href,
      children,
      disabled,
      isLoading,
      shallow,
      replace,
      scroll = false,
    }: {
      className?: string;
      href: string | UrlObject;
      children: React.ReactNode;
      disabled?: boolean;
      isLoading?: boolean;
      shallow?: boolean;
      replace?: boolean;
      scroll?: boolean;
    },
    ref: any,
  ) => {
    return disabled || isLoading ? (
      <div
        ref={ref}
        className={twMerge(
          `flex justify-center rounded-xl ${
            disabled
              ? 'bg-product-button-primary-disabled-dark text-product-button-primary-disabled-dark'
              : 'bg-product-button-primary-enabled-dark hover:bg-product-button-primary-hovered-dark focus:outline-none'
          } items-center px-6 text-center text-base font-semibold shadow-sm`,
          className,
        )}
      >
        {isLoading ? (
          <LoadingIconComponent className="h-6 w-6 animate-spin text-white"></LoadingIconComponent>
        ) : (
          children
        )}
      </div>
    ) : (
      <Link
        ref={ref}
        shallow={shallow}
        replace={replace}
        scroll={scroll}
        href={href}
        className={twMerge(
          `flex items-center justify-center rounded-xl bg-product-button-primary-enabled-dark px-6 text-center text-base font-semibold shadow-sm hover:bg-product-button-primary-hovered-dark focus:outline-none`,
          className,
        )}
      >
        {isLoading ? (
          <LoadingIconComponent className="h-6 w-6 animate-spin text-white"></LoadingIconComponent>
        ) : (
          children
        )}
      </Link>
    );
  },
);

export default PrimaryButtonLinkComponent;
