import Image from 'next/image';
import EditorsChoiceGooglePlay from '/public/images/landingpage-web/editorsChoiceGooglePlay.svg';
import EditorsChoiceStars from '/public/images/landingpage-web/editorsChoiceStars.svg';
import EditorsChoiceTitle from '/public/images/landingpage-web/editorsChoiceTitle.svg';

export default function EditorsChoiceBannerComponent() {
  return (
    <div className="z-[11] flex w-full justify-center px-6 tablet:px-10">
      <div className="relative flex h-[220px] w-full flex-col items-center space-y-4 overflow-hidden rounded-[32px] bg-product-accent-warning-light p-6 tablet:h-[134px] tablet:flex-row tablet:justify-between tablet:space-y-0 tablet:pl-14 tablet:pr-[130px]">
        <Image
          alt="editors choice"
          src={EditorsChoiceTitle}
          className="z-10 h-10 tablet:h-12"
        />
        <Image
          alt="editors choice"
          src={EditorsChoiceGooglePlay}
          className="z-10 h-[120px] w-[120px] tablet:h-[100px] tablet:w-[100px]"
        />
        <Image
          alt="editors choice"
          src={EditorsChoiceStars}
          className="absolute bottom-6 right-6 z-10 h-[80px] tablet:bottom-4 tablet:right-4 tablet:h-[100px]"
        />
        <div className="absolute -top-[100px] left-[15%] h-[500px] w-[20px] rotate-[30deg] bg-white/20"></div>
        <div className="absolute -top-[100px] left-[calc(15%+40px)] h-[500px] w-[60px] rotate-[30deg] bg-white/20"></div>
        <div className="absolute -top-[100px] left-[40%] hidden h-[500px] w-[20px] rotate-[30deg] bg-white/20 tablet:block"></div>
        <div className="absolute -top-[100px] left-[calc(40%+37px)] hidden h-[500px] w-[60px] rotate-[30deg] bg-white/20 tablet:block"></div>
        <div className="absolute -top-[100px] left-[calc(40%+113px)] hidden h-[500px] w-[20px] rotate-[30deg] bg-white/20 tablet:block"></div>
        <div className="absolute -top-[100px] left-[calc(40%+146px)] hidden h-[500px] w-[20px] rotate-[30deg] bg-white/20 tablet:block"></div>
        <div className="absolute -top-[100px] left-[calc(40%+182px)] hidden h-[500px] w-[60px] rotate-[30deg] bg-white/20 tablet:block"></div>
      </div>
    </div>
  );
}
