import React from 'react';
import { Paragraph1, Paragraph2 } from '../texts/Texts';
import Image, { StaticImageData } from 'next/image';
import { twMerge } from 'tailwind-merge';

export default function SubscribeTestimonialCardComponent({
  author,
  testimonial,
  authorImageSrc,
  role,
  companyImageSrc,
  className,
}: {
  author: string;
  testimonial: string;
  authorImageSrc: StaticImageData;
  role: string;
  companyImageSrc: StaticImageData;
  className?: string;
}) {
  return (
    <div
      className={`${twMerge(
        'flex flex-1 flex-col rounded-3xl border-2 border-product2-border-secondary p-6',
        className,
      )}`}
    >
      <div className="flex items-end justify-between">
        <Paragraph2 className="text-product2-content-secondary">
          {author}
        </Paragraph2>
        <Image
          className="h-10 w-10 rounded-full"
          src={authorImageSrc}
          alt="author"
        ></Image>
      </div>
      <Paragraph1 className="mt-4 text-product2-content-primary">
        &quot;{testimonial}&quot;
      </Paragraph1>
      <div className="mt-auto flex items-end justify-between pt-10">
        <Paragraph2 className="text-product2-content-secondary">
          {role}
        </Paragraph2>
        <Image
          className="max-w-[80px]"
          src={companyImageSrc}
          alt="company"
        ></Image>
      </div>
    </div>
  );
}
