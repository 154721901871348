import React from 'react';
import { StaticImageData } from 'next/image';
import SubscribeTestimonialCardComponent from './SubscribeTestimonialCard';
import designdough from '/public/images/subscribe/designdough.png';
import qrowdsy from '/public/images/landingpage/qrowdsy_light.svg';
import boots from '/public/images/subscribe/boots.png';
import zuzana from '/public/images/subscribe/zuzana_b.png';
import luca from '/public/images/subscribe/luca_p.png';
import charlotte from '/public/images/subscribe/charlotte_s.png';

interface Testimonial {
  author: string;
  testimonial: string;
  authorImageSrc: StaticImageData;
  role: string;
  companyImageSrc: StaticImageData;
}
export default function SubscribeTestimonialComponent() {
  const testimonials: Testimonial[] = [
    {
      author: 'Zuzana B.',
      testimonial: `Mimo's learning path provided a stable, sustainable pace for several months of constant progress.`,
      authorImageSrc: zuzana,
      role: 'Front-End Developer',
      companyImageSrc: qrowdsy,
    },
    {
      author: 'Luca P.',
      testimonial:
        'What I gained from the course was the ability to better understand coding and how to work on a project.',
      authorImageSrc: luca,
      role: 'Front-End Developer',
      companyImageSrc: designdough,
    },
    {
      author: 'Charlotte S.',
      testimonial:
        'I highly recommend Mimo to anyone looking to learn web development. I promise you will not regret it.',
      authorImageSrc: charlotte,
      role: 'Front-End Developer',
      companyImageSrc: boots,
    },
  ];

  return (
    <div className="flex flex-col gap-6 sm:flex-row">
      {testimonials.map((testimonial) => (
        <SubscribeTestimonialCardComponent
          className="last:hidden md:last:block"
          key={testimonial.author}
          author={testimonial.author}
          testimonial={testimonial.testimonial}
          authorImageSrc={testimonial.authorImageSrc}
          role={testimonial.role}
          companyImageSrc={testimonial.companyImageSrc}
        ></SubscribeTestimonialCardComponent>
      ))}
    </div>
  );
}
