import * as React from 'react';

function SQLIconComponent({
  className,
  grayscale,
}: {
  className?: string;
  grayscale?: boolean;
}) {
  return (
    <svg
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8C7.453 8 5 6.58 5 6C5 5.42 7.453 4 12 4C16.547 4 19 5.42 19 6C19 6.58 16.547 8 12 8ZM12 3C7.582 3 4 4.343 4 6V9C4 10.657 7.582 12 12 12C16.418 12 20 10.657 20 9V6C20 4.343 16.418 3 12 3ZM4 15.9277V18.9997C4 20.6567 7.582 21.9997 12 21.9997C16.418 21.9997 20 20.6567 20 18.9997V15.9277C18.424 17.2917 15.202 17.9997 12 17.9997C8.798 17.9997 5.576 17.2917 4 15.9277ZM4 13.9997V10.9277C5.576 12.2917 8.798 12.9997 12 12.9997C15.202 12.9997 18.424 12.2917 20 10.9277V13.9997C20 15.6567 16.418 16.9997 12 16.9997C7.582 16.9997 4 15.6567 4 13.9997Z"
        fill={grayscale ? 'white' : '#1E96FF'}
      />
    </svg>
  );
}

export default SQLIconComponent;
