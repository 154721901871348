import * as React from 'react';

function CssIconComponent({
  className,
  grayscale,
}: {
  className?: string;
  grayscale?: boolean;
}) {
  return (
    <svg
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.288 5.67089H17.6L17.376 7.85189L11.993 10.1239L11.98 10.1299H17.185L16.591 16.8919L12.002 18.2149L7.388 16.8669L7.09 13.4619H9.372L9.52 15.2259L11.976 15.8379L14.532 15.1279L14.705 12.2859L6.941 12.2619V12.2579L6.934 12.2619L6.76 10.1299L11.993 7.97789L12.299 7.85189H6.561L6.288 5.67089ZM3 1.99989L4.687 20.0559L12 21.9999L19.312 20.0559L21 1.99989H3Z"
        fill={grayscale ? 'white' : '#1E96FF'}
      />
    </svg>
  );
}

export default CssIconComponent;
