import * as React from 'react';

function CommunityIconComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="100%"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M40.5 75C59.83 75 75.5 59.33 75.5 40C75.5 20.67 59.83 5 40.5 5C21.17 5 5.5 20.67 5.5 40C5.5 59.33 21.17 75 40.5 75Z"
        fill="#3890FA"
      />
      <path d="M58.5 21V25H62.5V21H58.5Z" fill="#252746" />
      <path
        d="M32.5 9V13H28.5V17H20.5V21H16.5V25H12.5V29H20.5V37H24.5V49H28.5V69H32.5V65H36.5V57H40.5V53H44.5V49H48.5V45H44.5V41H36.5V37H24.5V29H28.5V25H36.5V17H40.5V9H32.5Z"
        fill="#252746"
      />
      <path
        d="M54.5 29H66.5V33H70.5V45H66.5V49H62.5V45H58.5V37H50.5V33H54.5V29Z"
        fill="#252746"
      />
    </svg>
  );
}

export default CommunityIconComponent;
