import * as React from 'react';
import { SVGProps } from 'react';

const QuoteEndDarkIconComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="50"
    height="40"
    viewBox="0 0 50 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="40"
      y="20"
      width="10"
      height="10"
      transform="rotate(-180 40 20)"
      fill="#252746"
    />
    <rect
      x="50"
      y="30"
      width="10"
      height="10"
      transform="rotate(-180 50 30)"
      fill="#252746"
    />
    <rect
      x="40"
      y="40"
      width="10"
      height="10"
      transform="rotate(-180 40 40)"
      fill="#252746"
    />
    <rect
      x="50"
      y="20"
      width="10"
      height="10"
      transform="rotate(-180 50 20)"
      fill="#252746"
    />
    <rect
      x="40"
      y="10"
      width="10"
      height="10"
      transform="rotate(-180 40 10)"
      fill="#252746"
    />
    <rect
      x="50"
      y="10"
      width="10"
      height="10"
      transform="rotate(-180 50 10)"
      fill="#252746"
    />
    <rect
      x="10"
      y="20"
      width="10"
      height="10"
      transform="rotate(-180 10 20)"
      fill="#252746"
    />
    <rect
      x="20"
      y="30"
      width="10"
      height="10"
      transform="rotate(-180 20 30)"
      fill="#252746"
    />
    <rect
      x="10"
      y="40"
      width="10"
      height="10"
      transform="rotate(-180 10 40)"
      fill="#252746"
    />
    <rect
      x="20"
      y="20"
      width="10"
      height="10"
      transform="rotate(-180 20 20)"
      fill="#252746"
    />
    <rect
      x="10"
      y="10"
      width="10"
      height="10"
      transform="rotate(-180 10 10)"
      fill="#252746"
    />
    <rect
      x="20"
      y="10"
      width="10"
      height="10"
      transform="rotate(-180 20 10)"
      fill="#252746"
    />
  </svg>
);
export default QuoteEndDarkIconComponent;
