import Image from 'next/image';
import React from 'react';

import EditorsChoiceAward from '/public/images/landingpage-web/EditorsChoiceAward.png';
import AppstoreRatingAward from '/public/images/landingpage-web/AppstoreRatingAward.png';
import BestSelfImprovementAppAward from '/public/images/landingpage-web/BestSelfImprovementAppAward.png';
import PlaystoreRatingAward from '/public/images/landingpage-web/PlaystoreRatingAward.png';

const StoreAwardsComponent = () => {
  return (
    <div className="grid w-full grid-cols-1 flex-col items-center justify-items-center gap-y-8 sm:grid-cols-2 tabletLg:grid-cols-4 tabletLg:gap-x-8 tabletLg:gap-y-0">
      <Image
        alt="Editors choice award"
        src={EditorsChoiceAward}
        className="object-contain"
        fill={false}
        width={220}
        placeholder="blur"
      />
      <Image
        alt="Appstore rating of 4.9"
        src={AppstoreRatingAward}
        className="object-contain"
        fill={false}
        width={220}
        placeholder="blur"
      />
      <Image
        alt="Best self-improvement app award"
        src={BestSelfImprovementAppAward}
        className="object-contain"
        fill={false}
        width={220}
        placeholder="blur"
      />
      <Image
        alt="Playstore rating of 4.7"
        src={PlaystoreRatingAward}
        className="object-contain"
        fill={false}
        width={220}
        placeholder="blur"
      />
    </div>
  );
};

export default StoreAwardsComponent;
