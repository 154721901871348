import MascotSurpriseIconComponent from '@components/illustrations/MascotSurprise';
import { Heading3 } from './Texts';
import PrimaryCTAComponent from './PrimaryCTA';
import { useMixpanel } from '@utils/tracking';
import { trackLinkToAppStore, trackLinkToPlayStore } from '@customtypes/events';
import { AppStoreBadgeComponent } from '@components/illustrations/AppStoreBadge';
import { PlayStoreBadgeComponent } from '@components/illustrations/PlayStoreBadge';
import { getRegisterPageLink } from '@utils/link-utils-web';

export default function StartLearningBanner() {
  const mixpanel = useMixpanel();

  function trackPlayStoreLink() {
    trackLinkToPlayStore(mixpanel);
  }

  function trackAppStoreLink() {
    trackLinkToAppStore(mixpanel);
  }
  return (
    <div className="relative m-auto flex h-[476px] w-full max-w-[1364px] flex-col items-center justify-between space-y-6 rounded-3xl bg-marketing-accent-purple px-8 py-10 text-marketing-text-primary tablet:h-[200px] tablet:max-h-[200px] tablet:flex-row tablet:space-y-6 tablet:p-10">
      <Heading3 className="text-center tablet:max-w-[52%] tablet:text-left tabletLg:hidden">
        Start learning to code on your phone
      </Heading3>
      <Heading3 className="hidden max-w-[50%] flex-1 text-center tablet:max-w-[48%] tablet:text-left tabletLg:block tabletLg:flex-1 desktop:max-w-[58%] desktopLg:max-w-[54%]">
        Start learning to code right now on your computer!
      </Heading3>
      <PrimaryCTAComponent
        href={getRegisterPageLink()}
        className="hidden rounded-xl bg-marketing-button-secondary-enabled px-4 py-4 text-marketing-button-secondary-text hover:bg-marketing-button-secondary-hover tabletLg:block"
        title="Start learning"
      ></PrimaryCTAComponent>
      <div className="flex h-16 flex-1 flex-col space-y-4 tablet:h-full tablet:flex-none tablet:space-y-4 tabletLg:hidden tabletLg:flex-row tabletLg:items-center tabletLg:space-x-4 tabletLg:space-y-0">
        <a
          onClick={trackAppStoreLink}
          target="_blank"
          rel="noreferrer"
          href="https://itunes.apple.com/us/app/mimo-learn-how-to-code-through/id1133960732?mt=8&at=1000lpyT"
        >
          <AppStoreBadgeComponent className="h-12"></AppStoreBadgeComponent>
        </a>
        <a
          onClick={trackPlayStoreLink}
          target="_blank"
          rel="noreferrer"
          href="https://play.google.com/store/apps/details?id=com.getmimo"
        >
          <PlayStoreBadgeComponent className="h-12"></PlayStoreBadgeComponent>
        </a>
      </div>
      <MascotSurpriseIconComponent className="absolute bottom-0 h-[140px] tablet:right-[240px] tabletLg:right-[280px] tabletLg:h-[160px]"></MascotSurpriseIconComponent>
    </div>
  );
}
