import { Heading5, BodyText } from './Texts';
import FunIconComponent from '@components/icons/funIcon';
import ProjectIconComponent from '@components/icons/projectIcon';
import CertificateIconComponent from '@components/icons/certificateIcon';
import TrophyIconComponent from '@components/icons/trophyIcon';
import PlaygroundIconComponent from '@components/icons/playgroundIcon';
import CommunityIconComponent from '@components/icons/communityIcon';
import { BenefitsConfetti } from './ConfettiBackground';

export default function Benefits() {
  const benefits = [
    'fun',
    'project',
    'certificate',
    'leaderboard',
    'playground',
    'community',
  ] as BenefitCard[];

  return (
    <div className="relative z-10 grid grid-flow-row grid-cols-1 justify-items-center gap-y-12 text-marketing-text-primary tablet:grid-cols-2 tablet:gap-x-10 tablet:gap-y-16 tabletLg:grid-cols-3 tabletLg:gap-x-8">
      <BenefitsConfetti />
      {benefits.map((benefit) => (
        <BenefitCard key={benefit} cardType={benefit}></BenefitCard>
      ))}
    </div>
  );
}

type BenefitCard =
  | 'fun'
  | 'project'
  | 'certificate'
  | 'leaderboard'
  | 'playground'
  | 'community';

function BenefitCard({ cardType }: { cardType: BenefitCard }) {
  return (
    <div className="flex max-w-[370px] flex-col space-y-6 text-center">
      {cardType === 'fun' && (
        <FunIconComponent className="h-[60px] tablet:h-20"></FunIconComponent>
      )}
      {cardType === 'project' && (
        <ProjectIconComponent className="h-[60px] tablet:h-20"></ProjectIconComponent>
      )}
      {cardType === 'certificate' && (
        <CertificateIconComponent className="h-[60px] tablet:h-20"></CertificateIconComponent>
      )}
      {cardType === 'leaderboard' && (
        <TrophyIconComponent className="h-[60px] tablet:h-20"></TrophyIconComponent>
      )}
      {cardType === 'playground' && (
        <PlaygroundIconComponent className="h-[60px] tablet:h-20"></PlaygroundIconComponent>
      )}
      {cardType === 'community' && (
        <CommunityIconComponent className="h-[60px] tablet:h-20"></CommunityIconComponent>
      )}
      <div className="flex-1 space-y-4">
        {cardType === 'fun' && (
          <>
            <Heading5>Gamified lessons</Heading5>
            <BodyText>
              Learn with interactive exercises, challenges, and projects,
              crafted by learning experts and backed by research
            </BodyText>
          </>
        )}
        {cardType === 'project' && (
          <>
            <Heading5>Real-world projects</Heading5>
            <BodyText>
              Build projects that apply your coding skills in real-life
              scenarios
            </BodyText>
          </>
        )}
        {cardType === 'certificate' && (
          <>
            <Heading5>Certificates</Heading5>
            <BodyText>
              Celebrate your accomplishments with shareable certificates as you
              reach milestones on your journey
            </BodyText>
          </>
        )}
        {cardType === 'leaderboard' && (
          <>
            <Heading5>Leaderboards</Heading5>
            <BodyText>
              Compete with fellow learners and climb the ranks as you complete
              exercises, challenges, and projects
            </BodyText>
          </>
        )}
        {cardType === 'playground' && (
          <>
            <Heading5>Playgrounds</Heading5>
            <BodyText>
              Unleash your creativity, from trying out concepts to prototyping
              your ideas and realizing your projects
            </BodyText>
          </>
        )}
        {cardType === 'community' && (
          <>
            <Heading5>Community</Heading5>
            <BodyText>
              Connect with the community to get guidance, share insights, and
              collaborate with your fellow learners
            </BodyText>
          </>
        )}
      </div>
    </div>
  );
}
