import * as React from 'react';

const HtmlIconComponent = ({
  className,
  grayscale,
}: {
  className?: string;
  grayscale?: boolean;
}) => (
  <svg
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3 2L4.64 20.01L11.99 22L19.37 20L21.01 2H3ZM6.34 5.68H17.63L17.42 7.89H8.81L9.02 10.15H17.22L16.6 16.93L12 18.18L7.38 16.93L7.06 13.46H9.32L9.48 15.22L12 15.88L14.51 15.22L14.77 12.35H6.96L6.34 5.68Z"
      fill={grayscale ? 'white' : '#FA965A'}
    />
  </svg>
);

export default HtmlIconComponent;
