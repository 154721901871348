import * as React from 'react';

function MascotSurpriseIconComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="100%"
      viewBox="0 0 134 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1189_19369)">
        <path
          opacity="0.3"
          d="M35.3008 150.457H128.618C131.261 150.457 133.403 152.593 133.403 155.228C133.403 157.864 131.261 160 128.618 160H35.3008C32.6579 160 30.5153 157.864 30.5153 155.228C30.5153 152.593 32.6579 150.457 35.3008 150.457Z"
          fill="#333661"
        />
        <path
          d="M89.6628 110.817L107.309 134.901"
          stroke="#A3A7CF"
          strokeWidth="21.4716"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M107.309 134.901L114.547 129.617"
          stroke="#A3A7CF"
          strokeWidth="21.4716"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M60.2098 115.863L60.4961 145.685"
          stroke="#A3A7CF"
          strokeWidth="21.4716"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M60.4969 145.685L69.4697 145.602"
          stroke="#A3A7CF"
          strokeWidth="21.4716"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M32.4594 8.82598L79.5872 0.540376C99.1082 -2.89163 117.723 10.1048 121.166 29.5687L129.475 76.5587C132.918 96.0226 119.883 114.583 100.362 118.015L53.2341 126.301C33.7131 129.733 15.0979 116.737 11.6558 97.2727L3.3459 50.2827C-0.0961758 30.8188 12.9384 12.258 32.4594 8.82598Z"
          fill="#EAEAF3"
        />
        <path
          d="M4.14026 47.1289L4.4466 47.075C6.80213 46.6609 9.04837 48.2291 9.46372 50.5778L15.2308 83.1889C15.6461 85.5375 14.0733 87.7772 11.7178 88.1913L11.4114 88.2452C9.0559 88.6593 6.80967 87.091 6.39432 84.7424L0.627244 52.1313C0.2119 49.7827 1.78473 47.543 4.14026 47.1289Z"
          fill="#EAEAF3"
        />
        <path
          d="M39.3798 13.67L86.5049 5.39147C102.776 2.52857 118.292 13.3599 121.152 29.5829L123.234 41.3327L125.315 53.0825C128.186 69.3056 117.323 84.7652 101.053 87.6281L53.9277 95.9186C37.657 98.7815 22.152 87.9502 19.2807 71.7271L17.1991 59.9773L15.1174 48.2275C12.2461 32.0045 23.1091 16.5448 39.3798 13.6819V13.67Z"
          stroke="#EAEAF3"
          strokeWidth="5.96434"
          strokeMiterlimit="10"
        />
        <path
          d="M86.5126 5.37772L39.3848 13.6633C23.1257 16.5219 12.2566 31.999 15.1235 48.2106L17.201 59.9581L123.239 41.3155L121.161 29.568C118.294 13.3564 102.772 2.51919 86.5126 5.37772Z"
          fill="#A8AACF"
        />
        <path
          d="M53.9295 95.9065L101.057 87.6209C117.316 84.7623 128.186 69.2851 125.319 53.0736L123.241 41.3261L17.2034 59.9687L19.2809 71.7162C22.1478 87.9278 37.6704 98.765 53.9295 95.9065Z"
          fill="#C4C6DE"
        />
        <path
          d="M115.266 30.6106L119.421 54.1056C121.715 67.0815 113.026 79.4554 100.012 81.7434L52.8839 90.029C39.8699 92.317 27.4597 83.6527 25.165 70.6768L21.0101 47.1818C18.7153 34.2059 27.405 21.832 40.419 19.544L87.5469 11.2584C100.561 8.97038 112.971 17.6347 115.266 30.6106Z"
          fill="#282A49"
        />
        <path
          d="M120.972 97.3862C118.99 97.3862 117.383 95.784 117.383 93.8076C117.383 91.8312 118.99 90.229 120.972 90.229C122.954 90.229 124.561 91.8312 124.561 93.8076C124.561 95.784 122.954 97.3862 120.972 97.3862Z"
          fill="#4FD95B"
        />
        <path
          d="M106.078 104.818C102.774 104.818 100.096 102.148 100.096 98.8535C100.096 95.5595 102.774 92.8892 106.078 92.8892C109.381 92.8892 112.06 95.5595 112.06 98.8535C112.06 102.148 109.381 104.818 106.078 104.818Z"
          fill="#A3A7CF"
        />
        <path
          d="M88.3948 107.919C85.0912 107.919 82.413 105.249 82.413 101.955C82.413 98.6611 85.0912 95.9907 88.3948 95.9907C91.6985 95.9907 94.3767 98.6611 94.3767 101.955C94.3767 105.249 91.6985 107.919 88.3948 107.919Z"
          fill="#A3A7CF"
        />
        <path
          d="M82.7818 59.9332L84.0283 66.9817L62.8208 70.7102L61.5743 63.6617L82.7818 59.9332Z"
          fill="#00C5FF"
        />
        <path
          d="M92.2233 49.6233C85.6159 49.6233 80.2596 44.2827 80.2596 37.6946C80.2596 31.1065 85.6159 25.7659 92.2233 25.7659C98.8307 25.7659 104.187 31.1065 104.187 37.6946C104.187 44.2827 98.8307 49.6233 92.2233 49.6233Z"
          fill="#00C5FF"
        />
        <path
          d="M45.0968 57.9021C38.4895 57.9021 33.1331 52.5615 33.1331 45.9734C33.1331 39.3854 38.4895 34.0447 45.0968 34.0447C51.7042 34.0447 57.0605 39.3854 57.0605 45.9734C57.0605 52.5615 51.7042 57.9021 45.0968 57.9021Z"
          fill="#00C5FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1189_19369">
          <rect
            width="132.845"
            height="160"
            fill="white"
            transform="matrix(-1 0 0 1 133.403 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default MascotSurpriseIconComponent;
