import * as React from 'react';

export function NewYearSaleBannerTransitionComponentSM(
  props: React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      height="100%"
      viewBox="0 0 24 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1440_182)">
        <rect
          width="8"
          height="48"
          transform="matrix(-1 0 0 1 8 0)"
          fill="#252746"
        />
        <rect
          width="8"
          height="8"
          transform="matrix(-1 0 0 1 24 0)"
          fill="#252746"
        />
        <rect
          width="8"
          height="8"
          transform="matrix(-1 0 0 1 8 56)"
          fill="#252746"
        />
        <rect
          width="8"
          height="8"
          transform="matrix(-1 0 0 1 16 48)"
          fill="#252746"
        />
        <rect
          width="8"
          height="8"
          transform="matrix(-1 0 0 1 16 8)"
          fill="#252746"
        />
        <rect
          width="8"
          height="8"
          transform="matrix(-1 0 0 1 16 16)"
          fill="#252746"
        />
      </g>
      <defs>
        <clipPath id="clip0_1440_182">
          <rect width="24" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function NewYearSaleBannerTransitionComponentMD(
  props: React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      height="100%"
      viewBox="0 0 24 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1440_267)">
        <rect
          width="8"
          height="56"
          transform="matrix(-1 0 0 1 8 0)"
          fill="#252746"
        />
        <rect
          width="8"
          height="48"
          transform="matrix(-1 0 0 1 8 64)"
          fill="#252746"
        />
        <rect
          width="8"
          height="8"
          transform="matrix(-1 0 0 1 24 0)"
          fill="#252746"
        />
        <rect
          width="8"
          height="8"
          transform="matrix(-1 0 0 1 16 88)"
          fill="#252746"
        />
        <rect
          width="8"
          height="8"
          transform="matrix(-1 0 0 1 16 56)"
          fill="#252746"
        />
        <rect
          width="8"
          height="8"
          transform="matrix(-1 0 0 1 16 104)"
          fill="#252746"
        />
        <rect
          width="8"
          height="8"
          transform="matrix(-1 0 0 1 16 112)"
          fill="#252746"
        />
        <rect
          width="8"
          height="8"
          transform="matrix(-1 0 0 1 24 96)"
          fill="#252746"
        />
        <rect
          width="8"
          height="8"
          transform="matrix(-1 0 0 1 16 8)"
          fill="#252746"
        />
        <rect
          width="8"
          height="8"
          transform="matrix(-1 0 0 1 16 16)"
          fill="#252746"
        />
      </g>
      <defs>
        <clipPath id="clip0_1440_267">
          <rect width="24" height="120" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function NewYearSaleBannerTransitionComponentLG(
  props: React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      height="100%"
      viewBox="0 0 24 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1440_145)">
        <rect
          width="8"
          height="48"
          transform="matrix(-1 0 0 1 8 0)"
          fill="#252746"
        />
        <rect
          width="8"
          height="48"
          transform="matrix(-1 0 0 1 8 64)"
          fill="#252746"
        />
        <rect
          width="8"
          height="8"
          transform="matrix(-1 0 0 1 24 0)"
          fill="#252746"
        />
        <rect
          width="8"
          height="8"
          transform="matrix(-1 0 0 1 16 8)"
          fill="#252746"
        />
        <rect
          width="8"
          height="8"
          transform="matrix(-1 0 0 1 16 16)"
          fill="#252746"
        />
        <rect
          width="8"
          height="8"
          transform="matrix(-1 0 0 1 16 120)"
          fill="#252746"
        />
        <rect
          width="8"
          height="8"
          transform="matrix(-1 0 0 1 16 112)"
          fill="#252746"
        />
        <rect
          width="8"
          height="8"
          transform="matrix(-1 0 0 1 16 56)"
          fill="#252746"
        />
        <rect
          width="8"
          height="8"
          transform="matrix(-1 0 0 1 16 72)"
          fill="#252746"
        />
        <rect
          width="8"
          height="8"
          transform="matrix(-1 0 0 1 24 64)"
          fill="#252746"
        />
        <rect
          width="8"
          height="8"
          transform="matrix(-1 0 0 1 16 48)"
          fill="#252746"
        />
        <rect
          width="8"
          height="8"
          transform="matrix(-1 0 0 1 8 128)"
          fill="#252746"
        />
        <rect
          width="8"
          height="8"
          transform="matrix(-1 0 0 1 8 120)"
          fill="#252746"
        />
      </g>
      <defs>
        <clipPath id="clip0_1440_145">
          <rect width="24" height="136" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
