import { assign, createMachine } from 'xstate';
import { isEmail } from '@utils/typeGuards';
import {
  didLoginOrRegister,
  trackPlatformVisit,
  submitRegister,
} from './registrationStateMachine';
import { UserCredential } from 'firebase/auth';

export type RegistrationComponentState = InitialRegistrationState;
export type EmailError = '' | 'Email is missing' | 'Invalid email address';
export type PasswordError =
  | ''
  | 'Password is missing'
  | 'Password must be at least 6 characters';
export type TermsError = '' | 'Required';

interface InitialRegistrationState {
  email: string;
  password: string;
  checkedTerms: boolean;
  errors: {
    email: EmailError;
    password: PasswordError;
    terms: TermsError;
    serviceError: string;
  };
  data?: UserCredential;
}

interface RegistrationStateContext {
  state: RegistrationComponentState;
}

export type RegistrationStateEvent =
  | {
      type: 'ENTER_EMAIL';
      email?: string;
    }
  | {
      type: 'ENTER_PASSWORD';
      password?: string;
    }
  | {
      type: 'TOGGLE_TERMS';
      checkedTerms?: boolean;
    }
  | {
      type: 'SUBMIT_FORM';
      data: {
        email?: string;
        password?: string;
        checkedTerms?: boolean;
      };
    }
  | {
      type: 'REGISTER_WITH_GOOGLE';
    };

const registrationWebLandingPageStateMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QCcxQJawC7IIZfQHsA7AZS3zADp1j0sBiAUQDkAVJgJQH0mBZAIIBJADIBtAAwBdRKAAOhWPSLFZIAB6IAbABYAHFQBMOgJwm9ARgCsFgOxbDAZh0AaEAE9EOnVapbHhnoSOloSEo4mVlYAvtFuqBjYeAQk5JQ0dIysHDwACgKkpADqAPKcACKSMkggCkopqjWaCA4Guno6hv46FhJatlZungjOJn62Jlp6hhbWVv4WsfFomDj4KmlY1LT0DGwlAOIHIkzcOXykVWp1yiRqzQ62fsYm9vO2tqauHoiGVo5UWxdKJhUIfCQmRxLEAJVbJDYULYZXakACqACE+EI2NwAGJlPhXGo3Br3RCOMJUOwWPRaCyQmm2CR6IaIGmGKimMy2OxWAbGWzQ2FJdapRHbTIMNGY7F4gliCzVeSKW6NUDNPQRKgSWyWCyGCT68KshA+HSA3omHr-SK9QVxGErEUNTYS3acJgHISkHLcIrYgAS3AOJUOJyJyvqKjJCE1Yx1eoNRscJqsEl8MwhnQsWgWdiFTrWLvFyMYHq9Pq4fsD3AEuVy4ek1xVpKaiDj2t1syTBpTPwQUV8PKzhgmjm79uWiSLCPSABtCLgILQoAwICQJQA3QgAa2owpnYvni+XxCgCFo24AxqLiFUI7UW9G2wgLI4AoDJh1PoafFpU104x6Jq46hI4DgxA6B7wkeSILkuK4MGAyDIIQyBUHIc74AAZmhAC2VDQberpUPBp7npehA3g095NsST53C+JijoC4QQp8jj2BE3zDCYzJGNmo6WDq9h6AW04wWQJbIahyDMOwVb8MI4h0ZGqoxhSEhUjytL0uOehMiy-ZOByZjck4HQBHy4lwsR0koWh8m+vkhSlBUD4ks+6rkpS1K6QyBnMiaAQGJEFJWrSJjWJYNnOrOSIyY5+xHCcZxcBcHkMWqGhsnoQ7+P0-Sap8wGDP2PRPDaUVdFo5jeJBU62cW6SJXJ0pYji+KcISqmPlGjHeQgEgmhIsWHlJLUOW1GIdXK3UKkqfXqS+w39qNUGFpJJGtQw5ber6-psEGIZhkwmX9dlzSadpNJ0gFhkmpYnJmWYMyOEEt1jVt9mybtnr7VWh1BnWDZnb1nkDTlIy+Tpd36Q9-ZvhyYRhE4bxpoYhhfXZ6SwMhm7oFeYBMFNTmKYIojnctg3XX5cOMkF-ZWAaVB5ZCXa3Xd2PNUiePIATRMk792RVi5xRlJU4NZRpMO3XpDNGcMdJaKz+ppn04RfFCG0STjvP44TxOk8lxynOclxSxdMtaXT8uBYriA8hybwOBSwEBP03PxdQfMC0bv3tbKXU9YtEOXV4zhUkETidIYdW2CanwWEYFghJxPjOOEWM6013tUL7htC45gedfKirNlbK0jV7sE+wbguk3tlY8EDwahqbVOtoN+hjPSfxTH80X9CarxPDSQS1b+-zMjXE36-zheN-9zfVkdtb1o2ofSy+tOw3bCPDN2ydWjYkThHyVjAbEDrEIQEBwGoRE82AFfU1DAC0dhjP4tK5r+BqdBNO-AyVB1YoyiHSQ0JhZ4kR2FgV+XcoZTBVo4GwVgehaHmH0HiiB0Eq0vlYZihlmajmgTnOKtdSInhXAgryUNZjoM7MJaYfEeSvAAuaPi-w4x8mCJqGBP00K0Mhs0T+44-DvVzKEVOADDAmm8OaDoThczFUNH8ARuN67+yEfRSug136oItKYVOkwugSANGVYYtIniZkmCVAYQIxLkPGiRWAABXK8RNYDwF0W-Zo1hAhUh0OmMw3EdQmAAgCTBNpgmvDUdfaIQA */
  createMachine(
    {
      context: {
        state: {
          email: '',
          password: '',
          checkedTerms: false,
          errors: {
            email: '',
            password: '',
            terms: '',
          },
          data: undefined,
        },
      } as RegistrationStateContext,
      types: {} as {
        context: RegistrationStateContext;
        events: RegistrationStateEvent;
        actors:
          | {
              src: 'trackPlatformVisit';
              id: 'trackPlatformVisit';
              logic: typeof trackPlatformVisit;
            }
          | {
              src: 'didLoginOrRegister';
              id: 'didLoginOrRegister';
              logic: typeof didLoginOrRegister;
            }
          | {
              src: 'submitRegister';
              id: 'submitRegister';
              logic: typeof submitRegister;
            };
      },
      id: 'registrationState',
      initial: 'init',
      states: {
        init: {
          on: {
            ENTER_EMAIL: {
              actions: 'captureEmail',
            },
            ENTER_PASSWORD: {
              actions: 'capturePassword',
            },
            TOGGLE_TERMS: {
              actions: 'captureToggledTerms',
            },
            SUBMIT_FORM: [
              {
                guard: 'isFormInvalid',
                target: 'error',
              },
              {
                target: 'loading',
              },
            ],
            REGISTER_WITH_GOOGLE: [
              {
                target: 'loading',
              },
            ],
          },
        },
        loading: {
          invoke: {
            src: 'submitRegister',
            id: 'submitRegister',
            input: ({ context, event }) => ({
              event,
              email: context.state.email,
              password: context.state.password,
            }),
            onDone: {
              actions: 'registrationSuccess',
              target: 'success',
            },
            onError: [
              {
                actions: 'captureServiceError',
                target: 'serviceError',
              },
            ],
          },
        },

        error: {
          entry: ['detectErrors'],
          on: {
            ENTER_EMAIL: {
              actions: 'captureEmail',
              target: 'init',
            },
            ENTER_PASSWORD: {
              actions: 'capturePassword',
              target: 'init',
            },
            TOGGLE_TERMS: {
              actions: 'captureToggledTerms',
              target: 'init',
            },
            SUBMIT_FORM: [
              {
                guard: 'isFormInvalid',
                target: 'error',
              },
              {
                target: 'loading',
              },
            ],
            REGISTER_WITH_GOOGLE: [
              {
                target: 'loading',
              },
            ],
          },
        },
        serviceError: {
          on: {
            ENTER_EMAIL: {
              actions: 'captureEmail',
              target: 'init',
            },
            ENTER_PASSWORD: {
              actions: 'capturePassword',
              target: 'init',
            },
            TOGGLE_TERMS: {
              actions: 'captureToggledTerms',
              target: 'init',
            },
            SUBMIT_FORM: [
              {
                guard: 'isFormInvalid',
                target: 'error',
              },
              {
                target: 'loading',
              },
            ],
            REGISTER_WITH_GOOGLE: {
              target: 'loading',
            },
          },
        },
        success: {
          invoke: [
            {
              src: 'didLoginOrRegister',
              id: 'didLoginOrRegister',
              input: ({ context }) => ({
                userCredentials: context.state.data,
              }),
            },
            {
              src: 'trackPlatformVisit',
              id: 'trackPlatformVisit',
              input: ({ context }) => ({
                userCredentials: context.state.data,
              }),
            },
          ],
        },
      },
    },
    {
      guards: {
        isFormInvalid: ({ context }) => {
          return (
            !isEmail(context.state.email) ||
            context.state.password.length < 6 ||
            context.state.checkedTerms === false
          );
        },
      },
      actions: {
        captureEmail: assign(({ context, event }) => {
          if (event.type === 'ENTER_EMAIL') {
            return {
              state: {
                ...context.state,
                email: event.email,
                errors: {
                  ...context.state.errors,
                  email: '',
                },
              } as InitialRegistrationState,
            };
          }
          return context;
        }),
        capturePassword: assign(({ context, event }) => {
          if (event.type === 'ENTER_PASSWORD') {
            return {
              state: {
                ...context.state,
                password: event.password,
                errors: {
                  ...context.state.errors,
                  password: '',
                },
              } as InitialRegistrationState,
            };
          }
          return context;
        }),
        captureToggledTerms: assign(({ context, event }) => {
          if (event.type === 'TOGGLE_TERMS') {
            return {
              state: {
                ...context.state,
                checkedTerms: event.checkedTerms,
                errors: {
                  ...context.state.errors,
                  terms: '',
                },
              } as InitialRegistrationState,
            };
          }
          return context;
        }),
        captureServiceError: assign(({ context, event }) => {
          return {
            state: {
              ...context.state,
              errors: {
                ...context.state.errors,
                serviceError: (event as any).error.message,
              },
            } as InitialRegistrationState,
          };
        }),
        detectErrors: assign(({ context, event }) => {
          if (event.type === 'SUBMIT_FORM') {
            return {
              state: {
                ...context.state,
                errors: {
                  email: emailError(context.state.email),
                  password: passwordError(context.state.password),
                  terms: !context.state.checkedTerms ? 'Required' : '',
                },
              } as InitialRegistrationState,
            };
          }
          return context;
        }),
        registrationSuccess: assign(({ context, event }) => {
          return {
            state: {
              ...context.state,
              data: (event as any).output,
            } as InitialRegistrationState,
          };
        }),
      },
      actors: {
        submitRegister: submitRegister,
        didLoginOrRegister: didLoginOrRegister,
        trackPlatformVisit: trackPlatformVisit,
      },
    },
  );

function emailError(email: string): EmailError {
  if (email.length === 0) {
    return 'Email is missing';
  }
  if (!isEmail(email)) {
    return 'Invalid email address';
  }
  return '';
}

function passwordError(password: string): PasswordError {
  if (password.length === 0) {
    return 'Password is missing';
  }
  if (password.length < 6) {
    return 'Password must be at least 6 characters';
  }
  return '';
}

export default registrationWebLandingPageStateMachine;
