import * as React from 'react';

const CheckmarkIconComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="square"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19.905 6.405 9 17.302l-4.177-4.177"
    />
  </svg>
);
export default CheckmarkIconComponent;
